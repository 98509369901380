#section05 {
	background-color: #7f3862;
	h2 {
		color: #9d4878;
		font-size: 43px;
		position: absolute;
		top:20px;
		left: 20px;
		margin:0px;
		width: 300px;
		background: url("../images/section05/seta.png") left 9px no-repeat;
		padding:0px 0px 0px 40px;
		span {
			border-top:1px solid #9d4878;
			margin-top:5px;
			padding-top: 5px;
			display: inline-block;
			width: 100%;
			font-size: 26px;
		}
	}
	.side {
		position: absolute;
		width: 50%;
		top:0;
		left:0;
		height: 100%;
		background: #eecfe5;
		z-index: 2;
		@media (max-width: 768px) {
			width: 100%;
		}
		.step {
			position: absolute;
			top:50%;
			left:50%;
			transform:translateX(-50%) translateY(-50%);
			&.step2, &.step3 {
				display: none;
			}
			&.step1 {
				font-size: 42px;
				font-weight: bold;
				a {
					display: inline-block; 
					color:#d95091;
					background: url("../images/section05/envelope.png") bottom center no-repeat;
					background-size: 90%;
					height: 470px;
					width: 435px;
					text-align: center;
					transition: transform .5s;
					&:hover {
						text-decoration: none;
						transform:scale(1.05);
					}
				}
			}
			&.step2 {
				width: 60%;
				max-width: 700px;
				top:50%;
				left:50%;
				transform: translateX(-50%) translateY(-50%);
				h3 {
					font-size:43px;
					color:#d95091;
					font-weight: bold;
					text-align:center;
					margin-bottom: 30px;
					&:before {
						margin-right: 10px;
						content:'';
						background: url("../images/section05/envelope.png") center center no-repeat;
						width: 80px;
						height: 80px;
						background-size: 100%;
						display: inline-block;
						vertical-align: middle;
					}
				}
				label {
					font-size:30px;
					width: 87px;
					margin-bottom: 0px;
					text-align: right;
					line-height: 50px;
					color: #d95091;
				}
				input {
					width: calc(100% - 120px);
					margin-left: 10px;
					border:1px solid #9d4878;
					background: none;
					height: 40px;
					border-radius:11px;
					vertical-align: bottom;
					font-size: 19px;
					padding-left: 10px;
					&.error {
						border: 1px solid #f50c0c;
					}
				}
				button {
					font-weight: bold; 
					font-size: 36px;
					background: #e59bc2;
					color: #9d4878;
					border:0px none;
					padding:0px 40px;
					border-radius: 10px;
					margin-top: 30px;
				}
			}
			&.step3 {
				h3 {
					font-size: 42px;
					font-weight: bold;
					display: inline-block; 
					color:#d95091;
					background: url("../images/section05/envelope.png") top left no-repeat;
					background-size: 90px;
					height: 90px;
					width: 295px;
					padding-left: 115px;
					text-align: left;
					transition: transform .5s;
					&:hover {
						text-decoration: none;
						transform:scale(1.1);
					}
				}
			}
		}
	}
	.video {
		position: absolute;
		width: 50%;
		top:0;
		right:0;
		background: #9d4878;
		height: 100%;
		margin-left: -1px;
	}
}

@media (max-width: 1200px) and (max-height: 690px)  {
	#section05 .side .step.step1 a {
		height: 331px;
		width: 297px;
	}
	#section05 .side .step.step2 h3 {
		font-size: 33px;
		margin-top: 70px;
	}
	#section05 .side .step.step2 input {
		height: 35px;
	}
	#section05 .side .step.step2 h3:before {
		width: 60px;
		height: 60px;
	}
}
@media (max-width: 768px) {
	#section05 h2 {
		font-size: 25px;
		width: 175px;
		padding: 0px 0px 0px 25px;
		background-size: 18px;
		background-position: left 4px;
	}
	#section05 h2 span {
		font-size: 15px;
	}
	#section05 .side .step.step1 {
		font-size: 30px;
	}
	#section05 .side .step.step2 h3 {
		font-size: 30px;
		margin-top: 70px;
	}
	#section05 .side .step.step1 a {
		background-size: 61%;
		height: 245px;
	}
	#section05 .side .step.step2{
		width: 95%;
	}
	#section05 .side .step.step2 label {
		font-size: 20px;
		line-height: 40px;
	}
	#section05 .side .step.step2 input {
		height: 34px;
	}
	#section05 .side .step.step2 button {
		font-size: 26px;
	}
	#section05 .side .step.step2 h3:before {
		width: 60px;
		height: 60px;
	}
	#section05 .side .step.step3 h3 {
		font-size: 32px;
		background-size: 70px;
		width: 211px;
		padding-left: 87px;
	}
}

@media (max-width: 414px) {
	#section05 .side .step.step1 a {
		background-size: 41%;
	}
}

@media (max-width: 375px) {
	#section05 .side .step.step1 a {
		background-size: 61%;
	}
}
@media (max-width: 375px) and (min-height: 812px) {
	#section05 .side .step.step1 a {
		background-size: 41%;
	}
}