#section02 {
	background: url("../images/section02/bg.jpg") bottom center no-repeat;
	background-size:auto 100%;
	background-color: #fee798;
	overflow: hidden;
	img {
		width: 100%;
		transform: translateY(-800px) rotate(0deg); ;
		transition: transform 1.8s ease-out;
		animation: section2denis 5s infinite;
		position: absolute;
	}

	&.fp-completely {
		.balao-section-2 {
			opacity: 1;
			animation: talksection2 .5s forwards;
		}
	}
	.denis-animate {
		position: absolute;
		top: 0px;
		right: 70px;
		width: 12%;
		transform: translateY(-800px) rotate(0deg); ;
		transition: transform 1.8s ease-out;
	}
	&.fp-completely {
		.denis-animate {
			transform: translateY(0px);
		}
	}
	.balao-section-2 {
		background: url("../images/section02/balao.png") center center no-repeat;
		background-size:100%;
		width: 350px;
		height: 220px;
		position: absolute;
		z-index: 4;
		color: #985675;
		font-size: 24px;
		text-align: center;
		top: 70%;
		left: 59%;
		line-height: 22px;
		transition: transform .5s, opacity .5s;
		padding: 69px 15px 20px 65px;
		font-weight: bold;
		opacity: 0;
		transform-origin: 0% 0%;
		b {
			font-weight: 900;
			color: #940c45;
		}
		img {
			width: 100%;
		}
	}
}


@keyframes talksection2{
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.25);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes section2denis {
	0% 	{top:50px; right: 70px;transform: rotate(0deg); }
	33% {top:80px; right:60px;transform: rotate(2deg);}
	66% {top:95px; right:80px;transform: rotate(0deg);}
	80% {top:80px; right:85px;transform: rotate(2deg);}
	100% {top:50px; right:70px;transform: rotate(0deg);}
}


@media (max-width: 768px) {
	#section02 {
		background-position: center center;
		background-size: 150%;
		img {
			top: 13%;
			right: 40px;
			width: 100%;
			transform: translateY(-1800px);
		}
		.denis-animate {
			top: 26%;
			right: 10px;
			width: 20%;
		}
	}
	@keyframes section2denis {
		0% 	{top:13%; right: 20px;transform: rotate(0deg); }
		33% {top:15%; right:10px;transform: rotate(2deg);}
		66% {top:17%; right:30px;transform: rotate(0deg);}
		80% {top:14%; right:35px;transform: rotate(2deg);}
		100% {top:13%; right:20px;transform: rotate(0deg);}
	  }
}

@media (max-width: 768px) {
	#section02 {
		background-position: center center;
		background-size: 150%;
		img {
			top: 13%;
			right: 40px;
			width: 100%;
			transform: translateY(-1800px);
		}
		.denis-animate {
			top: 16%;
			right: 10px;
		}
	}
	@keyframes section2denis {
		0% 	{top:13%; right: 20px;transform: rotate(0deg); }
		33% {top:15%; right:10px;transform: rotate(2deg);}
		66% {top:17%; right:30px;transform: rotate(0deg);}
		80% {top:14%; right:35px;transform: rotate(2deg);}
		100% {top:13%; right:20px;transform: rotate(0deg);}
	  }
	#section02 .balao-section-2 {
		width: 200px;
		height: 140px;
		font-size: 17px;
		top: 67%;
		left: 61%;
		line-height: 15px;
		padding: 30px 12px 18px 46px;
	}
}


@media (max-width: 414px) {
	#section02 .balao-section-2 {
		top: 63%;
		left: 51%;
	}
	#section02 .denis-animate {
		top: 25%;
	}
}




@media (max-width: 375px) {
	#section02 .balao-section-2 {
		top: 60%;
		left: 51%;
	}
	#section02 .denis-animate {
		top: 26%;
	}
}
@media (max-width: 375px) and (min-height: 812px)  {
	#section02 .denis-animate {
		top: 30%;
	}
}

@media (max-width: 320px) {
	#section02 .balao-section-2 {
		top: 67%;
		left: 41%;
	}
}


// @media (max-width: 375px) and (min-height: 812px) {
// 	#section02 {
// 		img {
// 			top: 120%;
// 			width: 100%;
// 		}
// 	}
// 	@keyframes section2denis {
// 		0% 	{top:20%; right: 20px;transform: rotate(0deg); }
// 		33% {top:25%; right:10px;transform: rotate(2deg);}
// 		66% {top:27%; right:30px;transform: rotate(0deg);}
// 		80% {top:24%; right:35px;transform: rotate(2deg);}
// 		100% {top:20%; right:20px;transform: rotate(0deg);}
// 	  }
// }