#header {
	text-align: center;
	transition: top 1s;
	.align {
		text-align: center;
	}
	video {
		position: absolute;
		top:0;
		left:50%;
		transform:translateX(-50%);
		height: 100%;
	}
}
#header{
	&.fp-completely {
		.talk {
			animation-delay: 1.5s!important;
			@media (max-width: 768px){ 
				animation-delay: 3.5s!important;				
			}
		}
	}
	.scroll-down {
		display: none;
	}
	&.fp-completely.active {
		.scroll-down {
			display: block; 
			position: absolute;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			img {
				max-width: 50px;
			}
		}
	} 

}
#header2 {
	&.active {
		.talk {
			animation-delay: 0s!important;
		}
	}

}
#header2,#header {
	&.fp-completely {
		.talk {
			opacity: 1; 
			animation: expand-bounce .5s forwards;
		}
	}
	&.active {
		.talk {
			// opacity: 1;s
		}
	}
	.talk {
		background: url("../images/section01/balao.png") center center no-repeat;
		background-size:100%;
		width: 250px;
		height: 170px;
		position: absolute;
		top:43%;
		left:50%;
		z-index: 4;
		color: #985675;
		font-size: 24px;
		line-height: 24px;
    	transform: scale(0);
		// transition: opacity .5s; 
		padding:28px 65px 30px 19px;
		margin-left: -18%;
		margin-top:-2%;
		opacity: 0;
		font-weight: bold;
		text-align: center;
		&.hideTalk {
			opacity: 0;
		}
		@media (max-width: 768px){
			background: url("../images/header/balaomobile.png") center center no-repeat;
			width: 168px;
			height: 105px;
			font-size: 16px;
			line-height: 16px; 	
			padding:12px 6px 10px 35px;
			left:calc(65% + 75px);
			top:52%;	
		}
	}
}
@keyframes expand-bounce {
  0% {
    transform: scale(0) translateX(-50%) translateY(-50%);
  }
  50% {
    transform: scale(1.25) translateX(-50%) translateY(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%) translateY(-50%);
  }
}
.bgvideo {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: -1;
	opacity: 0;
	background-color: #e07e70;
	video {
		position: absolute;
		top:0;
		left:50%;
		transform:translateX(-50%);
		height: 100%;
	}
}
.fp-viewing-sheader2,.fp-viewing-sheader,.fp-viewing-s1 {
	.bgvideo {
		opacity:1;
	}
}