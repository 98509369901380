body.fp-viewing-s9,body.fp-viewing-f1,body.fp-viewing-s8 {
	background: url("../images/section09/bg.jpg") bottom center no-repeat #ffda98;
	background-size:auto 100%; 
	@media (max-width: 767px){ 
		background: url("../images/section09/bg2.jpg") center center no-repeat #ffda98!important;	
		.bgs9 {
			position: fixed;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			text-align: center;
			z-index: -1;
			opacity: 1;
			background-color: #e07e70;
			background: url("../images/section09/bg2.jpg") center center no-repeat #ffda98!important;
		}

	}
}


@media (max-width: 768px) {
	body.fp-viewing-s9,body.fp-viewing-f1,body.fp-viewing-s8  {
		background-position: center center;
		background-size: 150%;
	}
}