#section08 {
	background: #ff8c79;
	.download {
		position: absolute;
		top: 67px;
		left: 20px;
		color: #5e0364;
		font-size: 30px;
		background: url("../images/section08/download.png") bottom center no-repeat;
		padding-bottom: 110px;
		width: 115px;
		line-height: 38px;
		text-align: center;
		background-size: 80%;
		height: 132px;
		&:hover {
			text-decoration: none;
		}
		@media only screen and (max-width: 768px){ 
			top:30px!important;
			height: 80px!important;
			background-size:100%;
			width: 80px;
			font-size: 24px; 
			padding-bottom:110px;
		}
		@media only screen and (max-width: 376px){ 
			padding-bottom:70px;
		}
	}
	.expand {
		width: calc(60% - 300px);
		display: inline-block;
		margin-left: 140px;
		position: absolute;
		top:50%;
		transform:translateY(-50%); 
		left:0;
		text-align:center;
		img {
			max-width: 90%;
		}
		@media (max-width: 768px){
			display: none;
		}
	}
	.carouselbox {
		position: absolute;
		top:60%;
		transform:translateY(-50%) ;
		left: calc(60% - 70px);  
		width: 40%;
		height: auto;
		padding-bottom:130px; 
		display: inline-block;
		@media (max-width: 768px){
			width: 100%;
			top:auto;
			bottom:10%;
			left:0px;
			transform: none;
		}
		@media (max-width: 375px) and (min-height: 812px){
			bottom:20%;
		}
		@media (max-width: 320px){
			bottom:10%;
		}
		.items {
			width: 100%;
			display: inline-block;
			height: 100%;
			@media (max-width: 768px){
				padding:30px;
			}
			img {
				max-height: 500px;
				max-width: 100%;
				height: auto;
				width:auto;
				display: inline-block;
				@media (max-height: 800px){
					max-height: 400px;
				}
				@media (max-width: 768px){
					max-height: 180px; 
				}
			}
			.item {
				text-align: center;
			}
			.selos {
				.centers {
					margin-top: 40%;
					transform:translateY(-59%);
				}
				a {
					width: 24%;
					display: inline-block;
					img {
						width: 100%;
					}
				}
			}
			.wallpappers {
				.centers {
					margin-top: 40%;
					transform:translateY(-59%);
					@media (max-width: 768px){ 
						transform:translateY(-90%);
					}
				} 
				a {
					width: 30%;
					margin:0px 1%;
					display: inline-block;
					img {
						border:2px solid white;
						box-shadow:0px 0px 5px rgba(0,0,0,0.1);
						width: 100%;
					}
				}
			}
		}
		.owl-nav {
			button {
				background: url("../images/section08/arrow.png");
				width: 38px;
				height: 76px;
				position: absolute;
				top:50%;
				transform:translateY(-50%);
				outline:0px none!important;
				span {
					display: none;
				}
				&.owl-prev {
					left:-50px;
				}
				&.owl-next {
					right:-50px;
					transform:rotate(180deg);
					margin-top:-30px;
				}
				@media (max-width: 768px){
					width: 20px;
					height: 40px;
					background-size: 100%;
					background-repeat:no-repeat; 
					&.owl-next {
						right: -25px;
					}
					&.owl-prev {
						left: -25px; 
					}
				}
			}
		}
		.tabs {
			text-align: center;
			position: absolute;
			bottom:0px;
			left:0px;
			width: 100%;
			z-index: 5;
			button {
				outline:0px none!important;
				&:after {
					content:'';
					background: #5e0364;
					width: 15px;
					height: 15px;
					position: absolute;
					top:-20px;
					left:50%;
					border-radius:10px;
					transform:translateX(-50%);
					border:2px solid #ff8c79;
					box-shadow:0px 0px 0px 2px #ff8c79;
					transition: border .5s, box-shadow .5s;
				}
				&:hover {
					&:after {
						border:2px solid #ff8c79;
						box-shadow:0px 0px 0px 2px #5e0364;						
					}
				}
				margin-top:30px;
				position: relative;
				display: inline-block;
				font-size:28px;
				color: #5e0364;
				border:0px none;
				background: none;
				width: 90px;
				line-height: 26px;
				@media (max-width: 1280px){ 
					font-size: 22px;
				}
				
			}
			@media (max-width: 768px){
				button {
					width: 65px;
					font-size: 22px;
				}
			}
		}
	}
}

@media (max-width: 1200px) and (max-height: 690px)  {
	#section08 .download {
		background-size: 60%;
		height: 110px;
		top: 80px;
	}
}
@media (max-width: 375px) and (min-height: 812px) {
	#section08 .download {
		height: 110px !important;
	}
}