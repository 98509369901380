#section07 {
	background: url("../images/section07/bg.jpg") center center no-repeat;
	background-size:auto 100%;
	background-color: #a493bf;
}

@media (max-width: 768px) {
	#section07 {
		background-position: center center;
		background-size: 150%;
	}
}