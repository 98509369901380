@font-face {
    font-family: 'Amatic SC';
    src: url('AmaticSC-Regular.woff2') format('woff2'),
        url('AmaticSC-Regular.woff') format('woff'),
        url('AmaticSC-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; 
}

@font-face {
    font-family: 'Amatic SC';
    src: url('AmaticSC-Bold.woff2') format('woff2'),
        url('AmaticSC-Bold.woff') format('woff'),
        url('AmaticSC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body,html {
    font-family: 'Amatic SC';
	height: 100%;
	overflow: hidden;
}
.content {
  position: fixed;
  left: 20px; 
  top: 40px;
  z-index: 99;
	width: 80px;
	height: 120px;
	margin-left:-80px;
	overflow: hidden;
		&:hover {
			overflow: initial;
			width: inherit;
			height:inherit;
			margin-left: 0px;
		}
  @media (max-width: 768px){
	top:10px;
	right: 10px;
	width: 70px;
	height: 110px;
	margin-left:-50px;
	overflow: hidden;
	@media (max-width: 768px){
		&.active {
			overflow: initial!important;
			width: inherit!important;
			height:inherit!important;
			margin-left: 0px!important;
		}
		&:hover {
			overflow: hidden;
			width: 70px;
			height: 110px;
			margin-left: -50px;
		}
		#menu {
			&:hover {
				transform:none;
			}
		}
	}
	@media (min-width: 768px){
		&.active {
			overflow: initial;
			width: inherit;
			height:inherit;
			margin-left: 0px;
		}
	}
  }
}

a:focus {
	text-decoration: none;
	outline: 0px none!important;
}
video:focus {
	text-decoration: none;
	outline: 0px none!important;
}

.loading {
	position: fixed;
	width: 100%;
	height: 100%;
	background: white;
	top:0;
	left:0;
	z-index: 100;
	.box {
		font-size: 22px;
		position: absolute;
		top:50%;
		left:50%;
		transform:translateX(-50%) translateY(-50%);
		text-transform: uppercase;
		b {
			display: inline-block;
			width: 100%;
			margin-top:10px;
		}
	}
}

#menu {
	display: block;
	list-style-type: none;
	min-width: 121px;
	background-size: 80px;
	transition: all 300ms ease-in-out;
	cursor: pointer;
	position: absolute;
	right: 0px;
	top:20px;
	padding:0px 30px 0px 0px;
  &:hover {
		transform:scale(1.03);
		margin-left: 2px;
		
		li {
			opacity: 1;
			transform:translateY(0);
			padding-right: 9px;
			border-radius: 50px;
			background: rgba(255,255,255,0);
			background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.26) 28%, rgba(228,169,163,0.93) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(28%, rgba(246,246,246,0.26)), color-stop(100%, rgba(228,169,163,0.93)));
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.26) 28%, rgba(228,169,163,0.93) 100%);
			background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.26) 28%, rgba(228,169,163,0.93) 100%);
			background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(246,246,246,0.26) 28%, rgba(228,169,163,0.93) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(246,246,246,0.26) 28%, rgba(228,169,163,0.93) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e4a9a3', GradientType=1 );
			
			a {
				font-size: 22px;
				&:before {
					width: 15px;
					height: 15px;
				}
			}
		}
  }
  &:before { 
		content:'Menu';
		color: white;
		display: inline-block;
		text-align:right;
		width: 100%;
		margin-left:5px;
		font-size: 24px;
		margin-bottom:80px;
  }
  &:after {
		content:'';
		background: url("../images/menu.png") right 30px no-repeat;
		background-size: 80px;
		position: absolute;
		top:0px;
		height: 100px;
		left:0px;
		width: 100%;
  }
  li {  	
	overflow: hidden;
	width: 240px;
	opacity:0;
	transform:translateY(-30px);
	transition: opacity 300ms, transform 300ms;
	text-align:right;
	&:hover {
		transform:scale(1.05);
		font-weight: bold;
		text-decoration:none; 
	}
  }
  @media (max-width: 768px){
		padding-left:0px;
		li {
			width: 195px;
		}
		&:after {
			background-size: 60px;
			background-position: right 30px;
		}
		&:before {
			font-size: 18px;
			margin-bottom: 65px;
		}
		&:hover {
			li {
				a {
					font-size: 18px;
					&:before {
						width: 10px;
						height: 10px;
					}
				}
			}
		}
  }
}
#menu li a {
	color:#636363;
	font-size: 22px;
	font-weight: bold;
	text-transform: uppercase;
	&:after {
		background: #636363;
		border-radius: 50px;
		display: inline-block;
		width: 15px;
		height: 15px;
		content:'';
		margin-left: 10px;
		transition: width .5s, height .5s;
	}
	&:hover {
		text-decoration: none;
	}
}


#fullpage {
	position: relative;
	width: 100%;
	text-align:center;
	.section {  
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100vh;
		position: relative;
		width: 100%;
	}
}

#footer09 {
	.footer {
		position: absolute;
		bottom:0px;
		left:0px;
		width: 100%;
		font-size: 20px;
		.top {
			padding:30px 0px;
			background: #edcece;
			color:#892357;
			display: inline-block;
			width: 100%;
			a {
				color:#892357;
				margin:0px 5px;
			}
		}
		.bottom {
			padding:50px 0px;
			background: #ee71a9;
			color:white;
			display: inline-block;
			width: 100%;
		}
	}
}

.align {
	text-align:left;
	width: 100%;
	margin:auto;
	position: relative;
	height: 100%;
}

@media (max-width: 768px) {
	#footer09 .footer .bottom img {
		width: 155px;
	}
	#footer09 .footer .bottom p {
		font-size: 13px;
		margin-top: 10px;
	}
	#menu {
		padding: 0px 15px 0px 0px;
	}
	
	#menu:before {
		margin-left: -1px;
	}
}

@import "section1"; 
@import "section2";
@import "section3";
@import "section4";
@import "section5";
@import "section6";
@import "section7";
@import "section8";
@import "section9";
@import "header";