#section06 {
	background: #ffebe8;
	overflow: hidden;
	&.active {
		.frente, .verso {
			transform:translateY(0);
		}
	}
	h2 {
		color: #9d4878;
		font-size: 43px;
		position: absolute;
		top:20px;
		left: 20px;
		margin:0px;
		width: 300px;
		background: url("../images/section05/seta.png") left 9px no-repeat;
		padding:0px 0px 0px 40px;
		span {
			border-top:1px solid #9d4878;
			margin-top:5px;
			padding-top: 5px;
			display: inline-block;
			width: 100%;
			font-size: 26px;
		}
	}
	.frente {
		width: calc(50% - 250px);
		top:0px;
		left: 320px;
		text-align: center;
		position: absolute;
		transform:translateY(-150%);
		transition: 2s;
		img {
			max-width: 100%;
		}
	}
	.verso {
		width: calc(50% - 250px);
		top:0px;
		left: calc(50% + 110px); 
		position: absolute;
		text-align: center;
		transform:translateY(-150%);
		transition: 3s;
		img {
			max-width: 100%;
		}
	}
}

@media (max-width: 768px) {
	#section06 h2 {
		font-size: 25px;
		width: 175px;
		padding: 0px 0px 0px 25px;
		background-size: 18px;
		background-position: left 4px;
	}
	#section06 {
		.frente, .verso {
			width: 50%;
			top:50%;
			left:0px;
			transform:translateY(-50%)!important;
		}
		.verso {
			left:50%;
		}
	}

}