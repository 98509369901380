#section03 {
	background: url("../images/section03/bg-section-3.jpg");
	background-size:auto 100%!important;
	overflow: hidden;
	&.fp-completely {
		.block {
			transform:scale(1) translateY(-24%) !important;
			opacity: 1!important;
		}
		.box1,.box2,.box3 {
			transform: translateY(0);
		}
		@media (max-width: 320px){
			.block {
				transform:scale(1) translateY(-8%) !important;
			}	
		}
	}
	.box1 {
		background: url("../images/section03/box1.jpg") no-repeat center center;
		background-size: 100%;
		height: 100%;
		width: 34.375%;
		position: absolute;
		left: 0;
		transform: translateY(-100%);
		transition: transform 1s ease-in-out;
	}
	.box2 {
		background: url("../images/section03/box2.jpg") no-repeat center center;
		height: 100%;
		background-size: 100%;
		width: 34.2578%;
		position: absolute;
		left: 34.375%;
		transform: translateY(+100%);
		transition: transform 1.5s ease-in-out;
	}
	.box3 {
		background: url("../images/section03/box3.jpg") no-repeat center center;
		height: 100%;
		width: 31.3671%;
		position: absolute;
		background-size: 100%;
		left: 68.6328%;
		transform: translateY(-100%);
		transition: transform 2s ease-in-out;
	}
	.block {
		position: absolute;
		background: url("../images/section03/block.png") no-repeat center center;
		background-size:30%;
		z-index: 2;
		&:nth-child(1){
			width: 34.375%;
			height: 100%;
			top:0%;
			left:0px;
			opacity: 0;
			transform:scale(1.2) translateY(-24%);
			transition: opacity 1s, transform 1s;
			transition-delay: 1s;
		}
		&:nth-child(2){
			width: 34.2578%;
			height: 100%;
			top:0%;
			left: 34.375%;
			opacity: 0;
			transform:scale(1.2) translateY(-24%);
			transition: opacity 1s, transform 1s;
			transition-delay: 1.5s;
		}
		&:nth-child(3){
			width: 31.3671%;
			height: 100%;
			top:0%;
			left: 68.6328%;
			opacity: 0;
			transform:scale(1.2) translateY(-24%);
			transition: opacity 1s, transform 1s;
			transition-delay: 2s;
		}
	@media (max-width: 768px) {
		&:nth-child(1),&:nth-child(2),&:nth-child(3){
			transform:scale(1.2) translateY(-5%)!important;
		}
	}
	@media (max-width: 768px) and (min-height: 1024px) {
		&:nth-child(1),&:nth-child(2),&:nth-child(3){
			transform:scale(1.2) translateY(-7%)!important;
		}
	}
	@media (max-width: 1024px) and (min-height: 1366px) {
		&:nth-child(1),&:nth-child(2),&:nth-child(3){
			transform:scale(1.2) translateY(-9%)!important;
		}
	}
	}
}