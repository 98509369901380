#section01 {
	overflow: hidden;
	background-color: #f0899f;
	/*.inverse {
		&.fada {	
			left:0px;		
			&:after, &:before, div {
				transform:rotateY(180deg);
			}
			div {
				left: auto;
				right: -120px;
				span {
					transform:rotateY(180deg);
					display: inline-block;
				}
			}
		}
	}*/
	&.hideFada {
		.fada {
			transform:translateY(-800px)!important;
		}
	}
	.video1 span, .video2 span {
		position: absolute;
		left:50px;
		bottom:50px;
		font-size: 20px;
		color: white;
		width: 400px;
		z-index: 3;
		transition: transform .5s;
		b {
			display: inline-block;
			width: 100%;
			line-height: 40px;
			font-size: 35px;
			margin-bottom: 20px;
			position: relative;
			&:after {
				position: absolute;
				bottom:-15px;
				left:0px;
				content:'';
				width: 80px;
				display: inline-block;
				border-bottom: 1px solid white;
			}
		}
	}
	.video1 {
		position: absolute;
		width: 50%;
		height: 100%;
		top:0;
		left:0;
		&:after {
			content:'';
			position: absolute;
			width: 100%;
			height: 100%;
			top:0;
			left:0;
			transition: all 0.5s ease-in-out;
			background: rgba(214, 128, 158, 0.59);
		}
		&:hover {
		}
	}
	.video2 {
		position: absolute;
		width: 50%;
		height: 100%;
		top:0;
		right:0;
		&:after {
			content:'';
			position: absolute;
			width: 100%;
			height: 100%;
			top:0;
			left:0;
			transition: all 0.2s ease-in-out;
			background: rgba(234,173,199,0.77);
		}
		&:hover {
		}
	}
	&.hideFada {
		.video1:hover {
			&:after {
				transform:translateY(100%);
				opacity: 0;
			}
			span {
				transform:translateY(300px);
			}			
		}
		.video2:hover {
			&:after {
				transform:translateY(100%);
				opacity: 0;
			}
			span {
				transform:translateY(300px);
			}			
		}
	}
	&.fp-completely {
		.fada {
			transform:translateY(0);
		}
	}
	&.talk1 {
		.fada {
			.talk1s {
				display: block;
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
	&.talk2 {
		.fada {
			.talk1s {
				transform: translateY(-100px);
				opacity: 0;
			}
			.talk2s {
				display: block;
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
	.fada {
		background: url("../images/section01/brilho.png") top left no-repeat;
		background-size:100%;
		width: 25%;
		position: absolute;
		z-index: 5;
		top: 80px;
		left: 70%;
		height: 100%;
		transform: translateY(-600px);
		transition: transform 1s;
		&:after {
			content:'';
			width: 100%;
			height: 100%;
			background: url("../images/section01/fada.png") top left no-repeat;
			background-size:100%;	
			position: absolute;		
			z-index: 2;
			top:0;
			left:0;
			transition: transform .3s;
			animation: fada 3s infinite;
		}
		&:before {
			content:'';
			width: 100px;
			height: 100px;
			top:300px;
			left: 0px;
			background: url("../images/section01/denis.png") top left no-repeat;
			background-size:100%;	
			position: absolute;		
			z-index: 3;
			transition: transform .3s;
			animation: denis 4s infinite;
		}
		div {
			background: url("../images/section01/balao.png") center center no-repeat;
			background-size:100%;
			width: 270px;
			height: 180px;
			position: absolute;
			top:10px;
			left:-200px;
			z-index: 4;
			color: #985675;
			font-size: 26px;
			line-height: 24px;
			transition: transform .5s, opacity .5s;
			padding:40px 65px 30px 20px;
			transform: translateY(-100px);
			opacity: 0;
			text-align:center;
		}
		.talk2s {
			padding-top:35px!important;
		}
		.talk1s {
			opacity:1;
			transform: translateY(0px);			
		}
	}

	@keyframes fadabox {
		0% {top:80px; left:70%;}
		33% {top:-600px; left:70%;}
		66% {top:-600px; left:0%;}
		100% {top:80px; left:0px;}
	}
	@keyframes fadabox2 {
		0% {top:80px; left:0%;}
		33% {top:-600px; left:0%;}
		66% {top:-600px; left:70%;}
		100% {top:80px; left:70%;}
	}
	@keyframes fada {
		0% {top:0px; left:0px;}
		50% {top:-10px; left:5px;}
		100% {top:0px; left:0px;}
	}
	@keyframes denis {
		0% {top:250px; left:0px;}
		50% {top:280px; left:10px;}
		100% {top:250px; left:0px;}
	}



}

@media (max-width: 768px) {
	#section01 .video1 span b, #section01 .video2 span b {
		line-height: 18px;
		font-size: 16px;
	}
	#section01 .video1 span, #section01 .video2 span {
		left: 10px;
		bottom: 67px;
	}
	#section01 .fada  {
		width: 45%;
		left: 45%;
	}
	#section01 .fada div {
		top: 30px;
    	left: -107px;
		font-size: 20px;
		line-height: 22px;
		width: 201px;
		height: 180px;
		padding-top: 49px;
	}
	#section01 .fada .talk2s {
		padding-top: 39px !important;
	}
	#section01 .fada:before {
		width: 48px;
		height: 58px;
	}
	#section01 .align {
		position: inherit; 
	}
	@keyframes denis {
		0% {top:280px; left:60px;}
		50% {top:250px; left:50px;}
		100% {top:280px; left:60px;}
	}

}
@media (max-width: 425px) {
	#section01 .fada  {
		width: 45%;
		left: 45%;
	}
	#section01 .fada div {
		top: -50px;
		left: -160px;
		font-size: 20px;
		line-height: 22px;
		width: 201px;
		height: 180px;
		padding-top: 49px;
	}
	#section01 .fada .talk2s {
		padding-top: 39px !important;
	}
	#section01 .fada:before {
		width: 48px;
		height: 58px;
	}
	@keyframes denis {
		0% {top:120px; left:0px;}
		50% {top:100px; left:10px;}
		100% {top:120px; left:0px;}
	}
}
@media (max-width: 320px) {
	#section01 .video1 span, #section01 .video2 span {
		width: 145px;
	}
	#section01 .fada div {
		top: -60px;
		left: -158px;
		font-size: 19px;
		line-height: 20px;
		width: 194px;
		height: 172px;
		padding: 49px 55px 28px 19px;

	}

}



