#section04 {
	background: url("../images/section04/bg.jpg") bottom center no-repeat;
	background-size:auto 100%;
	background-color: #ffbbce;
}

@media (max-width: 768px) {
	#section04 {
		background-position: center center;
		background-size: 150%;
	}
}